<template>
  <section
    id="articles"
    class="flex flex-col gap-4 lg:gap-6 mb-6 lg:mb-10"
    :class="{ hidden: !items.length }"
  >
    <h2
      class="max-w-[400px] text-neutral text-xl leading-7 font-bold uppercase text-center mx-auto mb-4"
    >
      {{ $t('home.news_title') }}
    </h2>
    <div id="articles-slider-container">
      <Splide
        :options="{
          focus: false,
          perMove: 3,
          drag: 'free',
          autoWidth: true,
          gap: '16px',
          pagination: false,
          trimSpace: 'move',
          omitEnd: true,
          i18n: {
            slide: ''
          },
          breakpoints: {
            640: {
              arrows: false
            }
          }
        }"
        class="splide-center mb-6"
      >
        <SplideSlide v-for="(item, index) in items" :key="index">
          <SiteBlogCard v-bind="item" :lazy="true"></SiteBlogCard>
        </SplideSlide>
      </Splide>
    </div>
  </section>
</template>

<script setup lang="ts">
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css/core';

import { fixImageUrl } from '@/helpers/fixes';
import { GET_WP_POSTS_HOME } from '@/utils/queries';

const SiteBlogCard = defineAsyncComponent(
  () => import('@/async-components/Site/Blog/Card.vue')
);

interface Article {
  image: string;
  imageAlt: string;
  title: string;
  excerpt: string;
  to: string;
}

interface ArticleFromResponse {
  title: string;
  excerpt: string;
  slug: string;
  categories: {
    nodes: {
      slug: string;
      parent?: {
        node: {
          slug: string;
        };
      };
    }[];
  };
  featuredImage?: {
    node?: {
      sourceUrl: string;
      altText: string;
    };
  };
}

const {
  public: { cloudflareImageProcessing: cfUrl }
} = useRuntimeConfig();

const { t, locale } = useI18n();

const client = useWpBlogClient();

const localePath = useLocalePath();

async function fetch() {
  const gqlResponse = await client.query<{
    posts: {
      nodes: ArticleFromResponse[];
    };
  }>({
    query: GET_WP_POSTS_HOME,
    variables: {
      language: locale.value === 'us' ? 'en' : locale.value
    }
  });

  return gqlResponse.data.posts.nodes.map(node => {
    const category = node.categories.nodes[0];

    // const parentCategory = category?.parent?.node || { slug: 'posts' };

    const path = localePath(
      `/${t('routes.blog')}` +
        // `/${parentCategory?.slug}` +
        `/${category?.slug}` +
        `/${node.slug}`
    );

    return {
      title: node.title,
      excerpt: node.excerpt,
      to: path,
      image: node.featuredImage?.node?.sourceUrl,
      imageAlt: node.featuredImage?.node?.altText
    };
  });
}

const { data: asyncItems, error } = await useAsyncData<Article[]>(async () => {
  if (process.client) {
    return [];
  }
  return await fetch();
});

const clientItems = ref<Article[]>([]);

onMounted(() => {
  if (!asyncItems.value?.length) {
    fetch().then(response => (clientItems.value = response));
  }
});

const items = computed(() => {
  const items = asyncItems.value?.length ? asyncItems.value : clientItems.value;

  return items.map(item => {
    const fixedImageUrl = item.image ? fixImageUrl(item.image) : '';
    const image = fixedImageUrl
      ? `${cfUrl}/image?w=352&h=202&f=webp&fit=crop&image=${fixedImageUrl}`
      : '';
    const imageSet = fixedImageUrl
      ? `${image} 1x, ${cfUrl}/image?w=704&h=404&f=webp&fit=crop&image=${fixedImageUrl} 2x`
      : '';
    return {
      ...item,
      image,
      imageSet
    };
  });
});
</script>

<style lang="scss">
@import '~/assets/css/splide-theme.scss';
</style>

<style scoped lang="scss">
#articles-slider-container :deep(.splide__arrow) {
  top: calc(50% - 72px);
}
</style>
